import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import background from '../styles/background.png';
import { SpinnerComponent } from '@entrycall/component-spinner';

interface SectionWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
}

const SectionWrapper: React.FC<SectionWrapperProps> = ({
  children,
  className,
  ...rest
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = background;
    img.onload = () => setImageLoaded(true);

    // Add white background when component mounts
    document.body.style.backgroundColor = 'white';
    
    // Cleanup when component unmounts
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const classes = classNames(
    className,
    'min-h-screen',
    'flex',
    'justify-center',
    'items-center',
    'tablet:min-h-screen',
    'bg-cover',
    'bg-center',
    'bg-no-repeat',
  );

  if (!imageLoaded) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <SpinnerComponent />
      </div>
    );
  }

  return (
    <main
      {...rest}
      className={classes}
      style={{ backgroundImage: `url(${background})` }}
    >
      {children}
    </main>
  );
};

export default SectionWrapper;
