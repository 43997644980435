import { redirect, type ActionFunctionArgs } from '@remix-run/node';
import {
  postLogin, 
  type LoginPayload,
  type VerificationPayload,
  API_CONFIG,
} from '@entrycall/lib-api-server';
import { handleError } from '../handleError.server';
import { validateResponse } from '../validateResponse.server';
import { URL } from 'url';
import { 
  FORM_INPUT_NAME_EMAIL, 
  FORM_INPUT_NAME_PHONE 
} from '../../../types';

type LoginMethod = 'email' | 'phone';

type LoginData = {
  identifier: string;
  loginMethod: LoginMethod;
}

// Debugger object to log information
const Debugger = {
  log: (message: string, data?: any) => {
    console.log(`[DEBUG] ${message}`, data || '');
  },
  error: (message: string, error?: any) => {
    console.error(`[ERROR] ${message}`, error || '');
  }
};

export async function authAction({request}: ActionFunctionArgs): Promise<Response> {
  const url = new URL(request.url);
  try {
    Debugger.log('Received auth action request', { url: request.url });
    if (url.searchParams.get('resend')) {
      return await resendVerificationCode(url);
    } else {
      const formData = await request.formData();
      Debugger.log('Form data received', formData);
      return await handleLoginByType(validateLoginData(formData));
    }
  } catch (error: unknown) {
    return handleErrorResponse('authAction', error);
  }
}

function validateLoginData(formData: FormData): LoginData {
  const user_email_content = formData.get(FORM_INPUT_NAME_EMAIL);
  const user_phone_content = formData.get(FORM_INPUT_NAME_PHONE);

  Debugger.log('Validating login data', { user_email_content, user_phone_content });

  if (user_email_content && typeof user_email_content === 'string') {
    return { identifier: user_email_content, loginMethod: FORM_INPUT_NAME_EMAIL };
  } else if (user_phone_content && typeof user_phone_content === 'string') {
    return { identifier: formatPhoneNumber(user_phone_content), loginMethod: FORM_INPUT_NAME_PHONE };
  }

  throw new Error('Invalid login data provided');
}

async function handleLoginByType(loginData: LoginData): Promise<Response> {
  const { identifier, loginMethod } = loginData;
  
  const encodedIdentifier = encodeURIComponent(identifier);
  const redirectUrl = `${API_CONFIG.WEBSITE_ROUTES.AUTHENTICATION.VERIFY_ROUTE.path}?${loginMethod}=${encodedIdentifier}`;

  Debugger.log('Handling login by type', { loginMethod, identifier, redirectUrl });

  const payload: LoginPayload = { loginId: identifier };
  
  try {
    const response = await postLogin(payload); // Send only the payload
    Debugger.log('API response received', response.data);
    validateResponse(response);
    return redirect(redirectUrl);
  } catch (error) {
    return handleErrorResponse('handleLoginByType', error);
  }
}

function formatPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/[\s\-()]/g, '');
}

async function resendVerificationCode(url: URL): Promise<Response> {
  try {
    const loginMethod = url.searchParams.get('method');
    const data = url.searchParams.get('data');

    Debugger.log('Resending verification code', { loginMethod, data });

    if (!loginMethod || !data) {
      throw new Error(
        'Invalid input: both method and data must be provided in the URL',
      );
    }

    const loginData: LoginData = {
      identifier: data,
      loginMethod: loginMethod as LoginMethod,
    };

    const payload: VerificationPayload = { loginId: loginData.identifier, verifyCode: '' };
    const response = await postLogin(payload);
    
    Debugger.log('Verification code resent successfully', response.data);

    validateResponse(response);

    return redirect(`${API_CONFIG.WEBSITE_ROUTES.AUTHENTICATION.VERIFY_ROUTE.path}?${loginData.loginMethod}=${encodeURIComponent(loginData.identifier)}`);
  } catch (error) {
    return handleErrorResponse('resendVerificationCode', error);
  }
}

function handleErrorResponse(context: string, error: unknown): Response {
  Debugger.error(`Error in ${context}`, error);
  
  // Assuming handleError returns an object with an error message and details
  const { error: errorMessage, details } = handleError(error);

  // Return a structured JSON response
  return new Response(JSON.stringify({ error: errorMessage, details }), {
    status: 400,
    headers: { 'Content-Type': 'application/json' },
  });
}