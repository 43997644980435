import React from 'react';
import OtpFormOrganism from '../../organisms/verify/verify-form';
import ArticleWrapper from '../section-wrapper.ui';

/**
 * OtpForm component - Renders the OTP (One-Time Password) form within a styled article.
 * This component uses articleWrapper to ensure consistent layout and styling.
 *
 * @returns {JSX.Element} A JSX element containing the OTP form.
 */
const OtpForm: React.FC = () => {
  return (
    <ArticleWrapper>
      <OtpFormOrganism />
    </ArticleWrapper>
  );
};

export default OtpForm;
