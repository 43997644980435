// lib/routes/auth/src/lib/frontend/components/molecules/auth/email/email-Input_fields.tsx

import React from 'react';
import AuthInputFields from '../input_fields'; // Importing the base input fields component
import { EnvelopeIcon } from '@heroicons/react/20/solid'; // Importing the icon for the email input
import { FORM_INPUT_NAME_EMAIL } from '../../../../../types';
// Props interface for the EmailInputField component
type EmailInputFieldProps = {
  error: string | null;
  onChange: () => void;
}

/**
 * EmailInputField Component
 *
 * This component renders an input field specifically for email input, including
 * an email icon and handling error display. It uses the AuthInputFields component
 * for consistent styling and behavior with other input fields.
 *
 * @param {EmailInputFieldProps} props - The properties for this component.
 * @returns {JSX.Element} The rendered email input field component.
 */
const EmailInputField: React.FC<EmailInputFieldProps> = ({ error, onChange }) => {
  const isInvalid = !!error;

  return (
    <AuthInputFields
      id="email" // Unique identifier for the input field
      name={FORM_INPUT_NAME_EMAIL} // Name attribute for the input, used in form submissions
      type="email" // Specifies that the input field is for email addresses
      placeholder="you@example.com" // Placeholder text shown inside the input field
      icon={<EnvelopeIcon className={`h-5 w-5 ${isInvalid ? `text-red-900` : `text-gray-500`}`}  />} // Icon displayed in the input field
      errorText={error} // The error message text to display, if any
      isInvalid={isInvalid} // Boolean to indicate if the input is in an invalid state
      onChange={onChange} // Function to handle input changes
    />
  );
};

export default EmailInputField;
