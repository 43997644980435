import React, { useState, useEffect } from 'react';
import { useNavigation } from '@remix-run/react';
import SectionWrapper from '../main-wrapper.ui';
import { SpinnerComponent } from '@entrycall/component-spinner';
import OtpForm from '../../components/templates/verify/verify-form';
import ArticleWrapper from '../../components/templates/section-wrapper.ui';

const VerifyPage: React.FC = () => {
  const navigation = useNavigation();
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingTime, setLoadingTime] = useState(0);

  const isNavigating = navigation.state === 'loading';

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isNavigating) {
      timer = setInterval(() => {
        setLoadingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setLoadingTime(0);
      setLoadingMessage('');
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isNavigating]);

  useEffect(() => {
    switch (loadingTime) {
      case 3:
        setLoadingMessage('Just a moment...');
        break;
      case 9:
        setLoadingMessage('Almost there...');
        break;
      case 15:
        setLoadingMessage('Hang on, this is taking longer than expected...');
        break;
      case 21:
        setLoadingMessage("We're working on it...");
        break;
      case 27:
        setLoadingMessage('Thank you for your patience...');
        break;
      default:
        if (loadingTime > 40) {
          setLoadingMessage('We apologize for the delay. Please wait a bit longer...');
        }
    }
  }, [loadingTime]);

  return (
    <SectionWrapper>
      {isNavigating ? (
        <ArticleWrapper>
          <SpinnerComponent />
          {loadingMessage &&  <p>{loadingMessage}</p>}
        </ArticleWrapper>
      ) : (
        <OtpForm />
      )}
    </SectionWrapper>
  );
};

export default VerifyPage;