import React from 'react';
import AuthInputField from './input_field';

interface AuthFormFieldsProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  icon: React.ReactNode;
  errorText: string | null;
  isInvalid: boolean;
}

const AuthInputFields: React.FC<AuthFormFieldsProps> = ({ id, name, type, placeholder, icon, errorText, isInvalid, onChange, ...rest }) => (
  <AuthInputField
    id={id}
    name={name}
    type={type}
    placeholder={placeholder}
    icon={icon}
    errorText={errorText}
    isInvalid={isInvalid}
    onChange={onChange} // Pass the onChange handler
    {...rest} // Spread other input attributes
  />
);

export default AuthInputFields;
