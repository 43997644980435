import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

const AuthErrorIcon = () => (
  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
    <ExclamationCircleIcon aria-hidden="true" className="h-5 w-5 text-red-500" />
  </div>
);

export default AuthErrorIcon;
