
import React from 'react';

interface IconWrapperProps {
  children: React.ReactNode;
}

const IconWrapper = ({ children }: IconWrapperProps) => (
  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-3 border-r-2">
    {children}
  </div>
);

export default IconWrapper;
