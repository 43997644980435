// app/routes/header-verify.tsx

import React, { useState, useEffect } from 'react';
import HeaderMolecule from '../header.molecules.ui';
import { useLoaderData } from '@remix-run/react';

const HeaderVerify: React.FC = ({description}) => {
  const { method, data } = useLoaderData<{
    method: string | null;
    data: string | null;
  }>();

  // const [timeLeft, setTimeLeft] = useState(180); // 3 minutes in seconds

  // useEffect(() => {
  //   if (timeLeft > 0) {
  //     const timerId = setTimeout(() => {
  //       setTimeLeft(timeLeft - 1);
  //     }, 1000);
  //     return () => clearTimeout(timerId);
  //   }
  // }, [timeLeft]);

  // const formatTime = (seconds: number) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  // };

  // const description =
  //   method === 'phone'
  //     ? `Enter the 6-digit verification code sent to your phone number ${data}.`
  //     : method === 'email'
  //       ? `Enter the 6-digit verification code sent to your email address ${data}.`
  //       : 'Enter the 6-digit verification code that was sent to your phone number or email address.';

  return (
    <HeaderMolecule
      title={`Code sent to ${data}`}
      // description={`${description} ${timeLeft ? `Code expires in: ${formatTime(timeLeft)}` : 'Code expired'}`}
      description={description}
    />
  );
};

export default HeaderVerify;
