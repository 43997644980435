import React from 'react';
import AuthFormOrganism from '../../organisms/auth/auth-form';
import SectionWrapper from '../section-wrapper.ui';

/**
 * AuthForm component - Renders the authentication form within a styled section.
 * This component uses SectionWrapper to ensure consistent layout and styling.
 *
 * @returns {JSX.Element} A JSX element containing the authentication form.
 */
const AuthForm: React.FC = () => {
  return (
    <SectionWrapper>
      <AuthFormOrganism />
    </SectionWrapper>
  );
};

export default AuthForm;
