import classNames from 'classnames';
import React from 'react';

interface TextAtomProps extends React.HTMLAttributes<HTMLParagraphElement> {
  text: string;
}

const TextAtom: React.FC<TextAtomProps> = ({ text, ...props }) => {
  const paragraphClasses = classNames(
    //* All screen sizes
    'text-slate-500',
    //* Min-width 325px
    'iPhone:text-xs',
    //* Min-width 640px
    'tablet:text-base'
  );

  return (
    <p className={paragraphClasses} {...props}>
      {text}
    </p>
  );
};

export default TextAtom;
