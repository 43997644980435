import classNames from 'classnames';
import React from 'react';

interface OtpInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string; // The value of the OTP input
  index: number; // The index of the input in the OTP sequence
  inputRef: (el: HTMLInputElement | null) => void; // Ref callback for the input element
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void; // Handler for change events
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => void; // Handler for key down events
  handleFocus: (e: React.FocusEvent<HTMLInputElement>) => void; // Handler for focus events
}

/**
 * OtpInput component - A single input field for entering a single digit in an OTP sequence.
 * @param {string} value - The value of the OTP input.
 * @param {number} index - The index of the input in the OTP sequence.
 * @param {(el: HTMLInputElement | null) => void} inputRef - Ref callback for the input element.
 * @param {(e: React.ChangeEvent<HTMLInputElement>, index: number) => void} handleChange - Handler for change events.
 * @param {(e: React.KeyboardEvent<HTMLInputElement>, index: number) => void} handleKeyDown - Handler for key down events.
 * @param {(e: React.FocusEvent<HTMLInputElement>) => void} handleFocus - Handler for focus events.
 * @param {Object} props - Additional props passed to the input element.
 */
const OtpInput: React.FC<OtpInputProps> = ({
  value,
  index,
  inputRef,
  handleChange,
  handleKeyDown,
  handleFocus,
  ...props
}) => {
  const classes = classNames(
    //* All screen sizes
    'mx-1',
    'w-11',
    'h-11',
    'text-center',
    'text-2xl',
    'font-extrabold',
    'text-slate-900',
    'bg-slate-100',
    'border',
    'border-transparent',
    'hover:border-slate-200',
    'appearance-none',
    'rounded',
    'outline-none',
    'focus:bg-white',
    'focus:border-indigo-400',
    'focus:ring-2',
    'focus:ring-indigo-100',
    //* Min-width 325px
    'iPhone:p-0',
    //* Min-width 640px
    'tablet:p-4',
    'tablet:mx-0',
    'tablet:w-14',
    'tablet:h-14'
  );

  return (
    <input
      className={classes}
      type="text"
      name={`verification-code-${index}`}
      ref={inputRef}
      pattern="\d*"
      maxLength={1}
      value={value}
      onChange={(e) => handleChange(e, index)}
      onKeyDown={(e) => handleKeyDown(e, index)}
      onFocus={handleFocus}
      {...props}
    />
  );
};

export default OtpInput;
