import React from 'react';
import SectionWrapper from '../main-wrapper.ui';
import AuthForm from '../../components/templates/auth/auth-form';

const AuthPage: React.FC = () => {
  return (
    <SectionWrapper>
      <AuthForm />
    </SectionWrapper>
  );
};

export default AuthPage;
