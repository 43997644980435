import React from 'react';
import classNames from 'classnames';

interface HeaderWrapperProps {
  className?: string; // Additional custom classes for the header
  children: React.ReactNode; // Children components to be rendered inside the header
}

/**
 * HeaderWrapper component - A reusable wrapper for header sections, providing consistent styling.
 * @param {string} [className] - Optional additional custom classes for the header.
 * @param {React.ReactNode} children - The children components to be rendered inside the header.
 * @returns {JSX.Element} A JSX element wrapping the header content.
 */
const HeaderWrapper: React.FC<HeaderWrapperProps> = ({ className, children }) => {
  const headerClasses = classNames(
    //* All screen sizes
    'flex',
    'flex-col',
    'items-center',
    'text-center',
    'space-y-4',
    'max-w-md',
    'mx-auto',
    'mb-4',
    //* Min-width 325px
    'iPhone:p-0',
    //* Min-width 640px
    'tablet:',
    className
  );

  return (
    <header className={headerClasses}>
      {children}
    </header>
  );
};

export default HeaderWrapper;
