import React from 'react';
import HeaderMolecule from '../header.molecules.ui';

const HeaderAuth: React.FC = () => {
  return (
    <HeaderMolecule
      title="Welcome Dear Tenant"
      description="Login in with"
    />
  );
};

export default HeaderAuth;
