// lib/routes/auth/src/lib/frontend/components/organisms/verify/verify-form.tsx

import { useEffect, useRef, useState } from 'react';
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from '@remix-run/react';
import HeaderMolecule from '../../molecules/verify/verify-header';
import OtpInputs from '../../molecules/verify/otp-inputs';
import ButtonAtom from '../../atoms/button.atom.ui';
import ErrorMessage from '../../atoms/auth/error_message-auth.atom.ui';
import { isMobile } from '@entrycall/utility-screen-size-checker';
import { type ActionData } from '../../../types';
import classNames from 'classnames';


// Numeric values object
const OTP_FORM_NUMBERS = {
  resendCountInitial: 0,
  timeLeftInitial: 180,  // 3 minutes in seconds
  resendButtonDisabledOpacity: 0.5,
  resendButtonEnabledOpacity: 1,
  codeResentTimeout: 1000,  // 1 second
  timerInterval: 1000,      // 1 second intervals
  timerResetValue: 3,       // Reset timer to 3 seconds for demonstration
};


// Styles object
const OTP_FORM_STYLE_CLASSES = {
  form: classNames('max-w-md', 'mx-auto'),
  buttonWrapper: (isMobileView: boolean) => classNames('mx-auto', 'mt-4', {
    'max-w-[260px]': !isMobileView
  }),
  resendContainer: classNames('text-sm', 'text-slate-500', 'mt-4'),
  resendButton: (timeLeft: number) => classNames('text-blue-600', 'hover:text-blue-800', 'ml-1', {
    'opacity-50 cursor-not-allowed': timeLeft > 0
  }),
  resendMessage: classNames('text-green-600', 'mt-2')
};

// Text object
const OTP_FORM_TEXT = {
  verifyAccount: 'Verify Account',
  loading: 'Loading...',
  didntReceiveCode: "Didn't receive code?",
  resend: 'Resend',
  codeResent: 'Code resent successfully',
  unexpectedError: 'An unexpected error occurred',
  codeExpired: 'Code expired',
  codeExpiresIn: 'Code expires in:',
  descriptionPhone: (data: string) => `Enter the 6-digit verification code sent to your phone number ${data}.`,
  descriptionEmail: (data: string) => `Enter the 6-digit verification code sent to your email address ${data}.`,
  descriptionDefault: 'Enter the 6-digit verification code that was sent to your phone number or email address.'
};

const OtpFormOrganism = () => {
  const { method, data } = useLoaderData<{
    method: string | null;
    data: string | null;
  }>();

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const submit = useSubmit();
  const navigation = useNavigation();
  const actionData = useActionData<ActionData>();
  const [resendCount, setResendCount] = useState(OTP_FORM_NUMBERS.resendCountInitial);
  const [timeLeft, setTimeLeft] = useState(OTP_FORM_NUMBERS.timeLeftInitial);
  const [errorText, setError] = useState<string | null>(null);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [resendMessage, setResendMessage] = useState('');
  const [resetTimer, setResetTimer] = useState(false);

  console.log('OtpFormOrganism - data', data);

  useEffect(() => {
    if (actionData && actionData.error) {
      setError(
        actionData.details?.errorMessage ||
        actionData.error ||
        OTP_FORM_TEXT.unexpectedError
      );
    } else {
      setError(null);
    }
  }, [data]);

  useEffect(() => {
    // Update the isMobileView state on component mount to ensure consistent client-side rendering
    setIsMobileView(isMobile());
  }, []);

  useEffect(() => {
    if (resendCount > 0) {
      setResendMessage(OTP_FORM_TEXT.codeResent);
      const timer = setTimeout(() => setResendMessage(''), OTP_FORM_NUMBERS.codeResentTimeout); // Clear message after 1 second
      return () => clearTimeout(timer);
    }
  }, [resendCount]);

  const handleResendCode = () => {
    submit(null, {
      method: 'post',
      action: '/auth/login?resend=true&method=' + method + '&data=' + data,
    });
    setResendCount((prevCount) => prevCount + 1);
    setTimeLeft(OTP_FORM_NUMBERS.timerResetValue); // Reset the timer to 1 minute
    setResetTimer((prev) => !prev); // Toggle this state to trigger useEffect
  };


  useEffect(() => {
    setTimeLeft(OTP_FORM_NUMBERS.timerResetValue);
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, OTP_FORM_NUMBERS.timerInterval);

    return () => clearInterval(timer);
  }, [resetTimer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const description = method === 'phone'
    ? OTP_FORM_TEXT.descriptionPhone(data || '')
    : method === 'email'
      ? OTP_FORM_TEXT.descriptionEmail(data || '')
      : OTP_FORM_TEXT.descriptionDefault;

  return (
    <article>
      <HeaderMolecule
        description={`${description} ${timeLeft ? `${OTP_FORM_TEXT.codeExpiresIn} ${formatTime(timeLeft)}` : OTP_FORM_TEXT.codeExpired}`}
      />
      <Form method="post" id="otp-form" className={OTP_FORM_STYLE_CLASSES.form}>
        <OtpInputs />
        <ErrorMessage errorText={errorText} />
        <div className={OTP_FORM_STYLE_CLASSES.buttonWrapper(isMobileView)}>
          <ButtonAtom
            ref={submitButtonRef}
            text={navigation.state === 'submitting' ? OTP_FORM_TEXT.loading : OTP_FORM_TEXT.verifyAccount}
          />
        </div>
      </Form>
      <div className={OTP_FORM_STYLE_CLASSES.resendContainer}>
        {OTP_FORM_TEXT.didntReceiveCode}
        <button
          onClick={handleResendCode}
          className={OTP_FORM_STYLE_CLASSES.resendButton(timeLeft)}
          disabled={timeLeft > 0}
        >
          {OTP_FORM_TEXT.resend} {timeLeft > 0 ? `(${formatTime(timeLeft)})` : ''}
        </button>
        {resendMessage && (
          <p className={OTP_FORM_STYLE_CLASSES.resendMessage}>{resendMessage}</p>
        )}
      </div>
    </article>
  );
};

export default OtpFormOrganism;