import React from 'react';
import classNames from 'classnames';
import { baseStyles } from '@entrycall/ui-tailwind';

interface ArticleWrapperProps {
  className?: string; // Additional custom classes for the section
  children: React.ReactNode; // Children components to be rendered inside the section
}

/**
 * SectionWrapper component - A reusable wrapper for sections, providing consistent styling.
 * This component is designed specifically for use with the OtpForm and AuthForm components,
 * ensuring a consistent layout and appearance across these forms.
 * @param {string} [className] - Optional additional custom classes for the section.
 * @param {React.ReactNode} children - The children components to be rendered inside the section.
 */
const SectionWrapper: React.FC<ArticleWrapperProps> = ({
  className,
  children,
}) => {
  const sectionClasses = classNames(
    //* All screen sizes
    'm-auto',
    'w-full',
    'max-w-xs',
    'p-2',
    'container',
    'mx-auto',
    'bg-white',
    'place-items-center',
    'text-center',
    'px-4',
    'py-10',
    baseStyles.borderRadius.extraLarge.rounded.default,
    'shadow',
    //* Min-width 640px
    'tablet:container',
    'tablet:max-w-xl',
    'tablet:p-2',
    'tablet:px-8',
    'tablet:py-10',
    'tablet:place-items-center',
    'tablet:text-center',
    className,
  );

  return <section className={sectionClasses}>{children}</section>;
};

export default SectionWrapper;
