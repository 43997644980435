import React from 'react';

interface ErrorMessageProps {
  id?: string;
  errorText?: string | null;
}

const ErrorMessage = ({ id, errorText }: ErrorMessageProps) => (
  <p id={`${id}-error`} className="mt-2 text-sm text-red-600">
    {errorText}
  </p>
);

export default ErrorMessage;
